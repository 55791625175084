<template>
  <div class="my-4">
    <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fieldProductSelect"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(name)="data">
        <div class="text-left">
          <p class="m-0 text-black" v-if="data.item.name">
            {{ data.item.name }}
          </p>
          <div>
            {{ data.item.article_no }}
            <span class="article-type" v-if="data.item.article_type">{{
              data.item.article_type
            }}</span>
          </div>
        </div>
      </template>
      <template v-slot:cell(price)="data">
        <p class="m-0 price-text" v-if="data.item.price">
          {{ data.item.price | numeral("0,0.00") }}
        </p>
        <p v-else class="m-0 price-text">-</p>
      </template>
      <template v-slot:cell(imge_url)="data">
        <div class="position-relative picture-text pic-table">
          <b-img
            class="preview-box-image"
            :src="data.item.imageUrl"
            @error="handleImageSrc"
          />
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="d-flex justify-content-center">
          <span v-show="false">{{ data.item.action }}</span>
          <b-button
            variant="link"
            class="text-warning px-1 py-0"
            @click.prevent="deleteProduct(data)"
          >
            <font-awesome-icon
              icon="trash-alt"
              title="Delete"
              class="main-color"
            />
          </b-button>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0"
      >
        <p class="mb-0 p-gray">
          Showing
          <span v-if="items.length === 0" id="from-row">0</span>
          <span v-else>
            {{ totalRows == 0 ? 0 : (currentPage - 1) * perPage + 1 }}</span
          >
          to
          <span>
            {{
              perPage * currentPage > totalRows
                ? totalRows
                : (perPage * currentPage) | numeral("0,0")
            }}</span
          >
          of <span>{{ totalRows }}</span> entries
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-end"
      >
        <div class="page-right">
          <b-pagination
            class="m-0"
            :total-rows="totalRows"
            v-model="currentPage"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    fieldProductSelect: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      perPage: 5,
      sortBy: "name",
      sortDesc: false,
    };
  },
  methods: {
    deleteProduct(item) {
      this.$emit("deleteSelectProduct", item.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
.page-right {
  float: right;
}
.preview-box-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  position: relative;
  max-width: 100px;
}
</style>
